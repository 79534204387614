.job-main-page {
  width: 100vw;
  background-color: #c9f0c4;
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  padding: 150px 0;
}

.job-main-page > .job-section {
  background-color: #93f069;
  text-decoration: none;
  color: black;
  padding: 20px 20px;
  border-radius: 10px;
}

.job-main-page > .job-section:hover {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: rgba(71, 220, 54, 0.971);
  color: #fff;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}
