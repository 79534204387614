@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

.business-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
}

.business-switch {
  display: flex;
  gap: 50px;
  margin: 20px 0 40px 0;
}

.business-switch > span {
  color: rgb(0, 0, 0);
  font-family: 'Fredoka', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 20px;
  cursor: pointer;
}

.business-switch > span.active {
  border-bottom: 2px solid rgb(111, 237, 14);
  transform: scale(1.2);
  padding-bottom: 1px;
}

.business-option {
  width: 100%;
}

.business-option > h3 {
  margin-bottom: 10px;
  font-family: 'Fredoka', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 20px;
}

.business-option > label {
  display: block;
  margin-bottom: 5px;
}

.business-option > input {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 10px;
  border: 0;
  outline: none;
}

.business-data {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.business-data > h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: 'Fredoka', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 20px;
}

.business-data > pre {
  white-space: pre-wrap;
}
