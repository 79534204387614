.user-form-container {
  max-width: 600px;
  margin: 30px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  animation: fadeInUp 0.5s ease-out;
  height: calc(100vh - 70px);
  overflow-y: auto;
}

.success-message {
  color: #28a745; /* Green color */
  font-size: 16px;
  position: absolute;
  z-index: 10;
  top: 57px;
}


form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin-top: 15px;
  margin-bottom: 8px;
  font-weight: bold;
  text-align: left;
}

.job-form-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s ease-in-out;
}

.job-form-i:focus,
.job-form-input:focus {
  outline: none;
  border-color: #007bff;
}

.job-post-button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.job-post-button:hover {
  background-color: #0056b3;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
