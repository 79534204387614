.farming-container1 {
  background-color: #c9f0c4;
  color: rgb(0, 0, 0);
  opacity: 0; /* Initially set opacity to 0 */
  animation: fade-in 1.5s ease-in-out forwards; /* Animation for fade-in */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.farming-main-container1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.fade-in {
  animation: fade-in 1.5s ease-in-out forwards;
}

.first-container1 {
display: flex;
flex-wrap: wrap;
justify-content: flex-end;
width: 100vw;
position: relative;
}

.first-text1 {
width: 50vw;
color: transparent; /* Make text transparent */
position: absolute;
left: 20px;
top: 25%;
animation: fadeIn 2s ease-in-out;
background-image: linear-gradient(
  45deg,
  #ffd700,
  #ff4500
); /* Add gradient color */
-webkit-background-clip: text; /* Clip the background to the text */
background-clip: text;
text-fill-color: transparent; /* Make text color transparent */
}

.first-text1 > p {
font-size: 20px;
}

.hero-image1 {
background: url("../img/farming-page-image.png") center/cover no-repeat;
width: 100vw;
height: 100%;
}

.glow {
color: #000000;
font-size: 36px;
text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

@keyframes fadeIn {
from {
  opacity: 0;
  transform: translateY(20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
}

.digital-solution1 {
text-align: center;
color: rgb(0, 0, 0);
animation: fadeIn 2s ease-in-out;
background-image: linear-gradient(
  45deg,
  #ffd700,
  #ff4500
); /* Add gradient color */
-webkit-background-clip: text;
}

@media screen and (min-width: 675px) {
.first-text1 > h3 {
  font-size: 70px;
}
.first-text1 > p {
  font-size: 40px;
}

.hero-image1 {
  width: 100%;
  height: 805px;
}
}


.bottom-sections1 {
display: flex;
flex-wrap: wrap;
justify-content: space-around;
margin: 20px 0;
}

.add-section1 {
width: 100%;
max-width: 300px;
margin: 20px;
padding: 20px;
background-color: #93f069;
color: #333;
text-align: center;
border-radius: 8px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
overflow: hidden;
position: relative;
transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
  background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.add-section1:hover {
transform: translateY(-5px) scale(1.05);
box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
background-color: rgba(71, 220, 54, 0.971);
color: #fff;
}

.add-section1 > h2 {
transition: transform 0.3s ease-in-out;
}

.add-section1:hover h2 {
transform: translateY(-5px);
}

.add-section1 > p {
transition: transform 0.3s ease-in-out;
}

.add-section1:hover p {
transform: translateY(-5px);
}
