.chat {
  display: flex;
  justify-content: center;
  background-color: #c9f0c4;
  overflow-y: hidden;
  min-height: calc(100vh - 60px);
}


.chat-page {
  height: calc(100vh - 140px);
  width: 90%;
  overflow-y: auto;
  background-color: #b7c6b0;
  border-radius: 20px;
}

.ai-flex-box {
  display: flex;
  padding: 10px;
  position: fixed;
  bottom: 15px;
  width: 90%;
  gap: 10px;
}

.ai-chat-search {
  width: 100%;
  height: 45px;
  border-radius: 15px;
  border: none;
  padding: 4px 10px;
  font-size: 20px;
  position: relative;
  color: black;
  background-color: white;
}

.button-flex-box {
  height: 45px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
}
.ai-chat-button {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #09e083 0,
    #1a7624c6 100%
  );

  padding: 5px 9px;
  font-size: 17px;
  width: 57px;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(44, 105, 68, 0.3) 0 7px 13px -3px, rgba(47, 98, 77, 0.5) 0 -3px 0 inset;
}

.ai-chat-search:focus {
  outline: none;
}

.ai-chat-search::placeholder {
  color: rgb(117, 113, 113);
  font-size: 15px;
}

@media screen and (min-width: 900px) {
  .chat-page {
    width: 800px;
  }

  .ai-flex-box {
    width: 800px;
  }
}

@media screen and (max-width: 600px) {
  .chat {
    height: calc(100vh - 45px);
  }
}

.ai-chat-button:disabled {
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #313232 0,
    #313231c6 100%
  );
  box-shadow: rgb(54, 54, 54) 0 2px 4px, rgba(42, 42, 42, 0.3) 0 7px 13px -3px,
    rgb(50, 50, 50) 0 -3px 0 inset;
}

.user,
.ai {
  padding: 5px 10px;
  color: rgb(0, 0, 0);
}

.user-prompt,
.ai-response {
  padding: 5px 20px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  line-height: 1;
}
