@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap");

.farming-header {
  background: url("../img/farming-backgroud.jpeg") no-repeat center/cover;
  min-height: 200px;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
}

.header-slogan {
  padding: 10px 25px;
  font-family: "Source Code Pro", monospace;
  color: hwb(96deg 83% 0%);
  font-size: 40px;
  font-weight: 900;
}


.search-section {
  margin: 30px 0;
  gap: 20px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.all-category,
.location-search {
  padding: 0 15px;
  width: 300px;
  height: 50px;
  border: none;
  border-radius: 12px;
}

.farming-search-btn {
  border: none;
  height: 50px;
  padding: 10px 30px;
  background-color: #1afbaf;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  letter-spacing: 2px;
}

.farming-h1-heading {
  text-align: center;
  color: rgb(0, 0, 0);
  margin: 20px 0;
}

.farming-description {
  margin: 20px 0;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 25px;
}

.appear-from-left {
  opacity: 0; /* Initially set opacity to 0 */
  transform: translateX(
    -100%
  ); /* Move the element outside the viewport to the left */
  animation: appearFromLeft 1s forwards; /* Animation duration and fill-mode */

  /* Additional styling */
  padding: 20px;
  /* background-color: #f0f0f0; */
}

@keyframes appearFromLeft {
  to {
    opacity: 1; /* Set opacity to 1 at the end of the animation */
    transform: translateX(
      0
    ); /* Move the element to its original position (0% from the left) */
  }
}

.listed-categories {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  padding: 20px 100px;
}
.card-container {
  width: 200px;
  height: 300px;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
}

.farm-category {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.card-name {
  margin-top: 40px;
}


/* .card-container:hover {
  background-color: red;
  transform: scale(2);
} */

.product-search {
  text-decoration: none;
  color: black;
}

.card-container:hover {
  text-decoration: none;
  background-color: rgba(71, 220, 54, 0.971);
}

.demo-product {
  color: white;
}
