@import url('https://fonts.googleapis.com/css2?family=Protest+Strike&display=swap');
/* SliderCarousel.css */

.slide {
  position: relative;
}

.slide img {
  width: 100%;
  height: auto;
}


.slider-container {
  position: relative; /* Ensure the container is positioned relatively */
  width: 100%;
}

/* Adjust the positioning of the navigation buttons */
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%; /* Position the buttons vertically centered */
  transform: translateY(-50%);
  z-index: 1; /* Ensure the buttons are above the slides */
  background: transparent;
  border: none;
  cursor: pointer;
}

.slick-prev {
  left: 20px; /* Adjust the left position of the previous button */
}

.slick-next {
  right: 20px; /* Adjust the right position of the next button */
}

.hero-text1, .hero-text2, .hero-text3, .hero-text4 {
  font-family: "Protest Strike", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 5vw;
  color: #fff;
  letter-spacing: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
}
/* Hero Text 1 */
.hero-text1 {
  text-align: center;
  position: absolute;
  top: 5%;
  left: 0%;
}

/* Hero Text 2 */
.hero-text2 {
  text-align: center;
  position: absolute;
  top: 0%;
  left: 0;
}

/* Hero Text 3 */
/* .hero-text3 {
  text-align: center;
  position: absolute;
  top: 10%;
  left: 0;
} */

/* Hero Text 4 */
.hero-text4 {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 40%;
}

