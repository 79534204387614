.business-data {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .business-data h3 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .business-data table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
  }
  
  .business-data th,
  .business-data td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .business-data th {
    background-color: #f2f2f2;
  }
  
  .business-data th:first-child,
  .business-data td:first-child {
    width: 40%;
  }
  