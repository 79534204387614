
  .crop-reco-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 900px;
    background-color: aliceblue;
  }
  
  
  .form-group {
    margin-bottom: 10px;
    width: 50vw;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-group button {
    padding: 10px 20px;
    background-color: #55ff00;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .form-group > button:disabled {
    cursor: not-allowed;
    background-color: #ddd;
    color: #999;
  }
  
  .form-group button:hover {
    background-color: #23a81a;
  }
  
  #result {
    margin-top: 15px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  