@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap");

.container {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 100vh;
  background-color: aliceblue;
}

.predict-heading {
  margin-top: 50px;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: rgb(0, 0, 0); /* Adjust text color */
}

input[type="file"] {
  margin-bottom: 1rem;
}

button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: rgba(71, 220, 54, 0.971);
  color: white; /* Adjust text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

button:hover {
  background-color: #297022;
}

.prediction {
  margin-top: 1rem;
  color: rgb(2, 2, 2); /* Adjust text color */
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.5rem;
  }

  button {
    font-size: 0.9rem;
  }
}
