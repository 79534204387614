@import url("https://fonts.googleapis.com/css2?family=Protest+Riot&display=swap");

.cta-section {
  padding: 0px 10px;
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: #c9f0c4;
  margin-top: -6px;
  padding-top: 20px;
}

.cta-section > h2 {
  text-align: center;
  font-family: "Protest Riot", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.cta-section > h2::after {
  content: "";
  display: block;
  background-image: url("../img/title_shape.svg");
  width: 79px;
  height: 14px;
  margin: auto;
  background-repeat: no-repeat;
}

.home-flex-box {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.home-each-content > h2 {
  text-align: center;
  margin-bottom: 10px;
}

.home-each-content {
  padding: 30px;
}

.home-each-content > h2::after {
  content: "";
  display: block;
  background-image: url("../img/title_shape.svg");
  width: 79px;
  height: 14px;
  margin: auto;
  background-repeat: no-repeat;
}

.home-each-content > p {
  text-align: justify;
  font-size: 19px;
}

.dot-container > img {
    width: 300px;
    height: 300px;
    object-fit: cover;
}

@media screen and (max-width: 500px) {
  .cta-section > h2 {
    font-size: 20px;
  }
}

@media screen and (min-width: 780px) {
  .home-flex-box:nth-child(even) > .dot-container {
    order: 2;
  }

  .home-flex-box:nth-child(even) > .home-each-content {
    order: 1;
  }
}

.dot-container {
  width: 300px;
  height: 300px;
  filter: blur(1px);
  border-radius: 50%;
  overflow: hidden;
}

.home-each-content {
  width: 400px;
  height: auto;
}
